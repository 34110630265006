import React from 'react';
import { JibTheme } from 'themes/jib';
import { useLocation } from 'react-router-dom';
import APCResult from 'assets/APC_result.jpeg';
import APCResult2 from 'assets/APC_result_2.jpeg';
import EnrolmentHeader from './EnrolmentHeader';
import EnrolmentFooter from './EnrolmentFooter';

const resultProgramInfo = [
  {
    type: 'cardiometabolic',
    name: 'Cardiometabolic program',
    detailsTitle: 'Cardiometabolic Program',
    detailsDescription: 'We believe that if you weren’t born with it, it can be reversed. This program has helped people like you lose weight, reduce A1C, and reduce or eliminate medications',
    image: APCResult2
  },
  {
    type: 'apc',
    name: 'Advanced Primary Care',
    detailsTitle: 'Advanced Primary Care Program',
    detailsDescription: 'Think of this program as the ultimate compliment to your work with a primary care provider. Whether you’re experiencing symptoms and don’t have a diagnosis yet, or you want to lose weight, have more energy, or reduce chronic pain, this program can help you meet your health goals for a happier, healthier life.',
    image: APCResult
  },
  {
    type: 't2dr',
    name: 'Type 2 Diabetes Reversal',
    detailsTitle: 'Type 2 Diabetes Reversal Program',
    detailsDescription: 'We believe that if you weren’t born with it, it can be reversed. This program has helped people like you lose weight, reduce A1C, and reduce or eliminate medications',
    image: APCResult2
  },
  {
    type: 'da',
    name: 'Depression/Anxiety Program',
    detailsTitle: 'Depression/Anxiety  Program',
    detailsDescription: 'Think of this program as the ultimate compliment to your work with a primary care provider. Whether you’re experiencing symptoms and don’t have a diagnosis yet, or you want to lose weight, have more energy, or reduce chronic pain, this program can help you meet your health goals for a happier, healthier life.',
    image: APCResult
  },
  {
    type: 'msk',
    name: 'Musculoskeletal Program',
    detailsTitle: 'Musculoskeletal Program',
    detailsDescription: 'Think of this program as the ultimate compliment to your work with a primary care provider. Whether you’re experiencing symptoms and don’t have a diagnosis yet, or you want to lose weight, have more energy, or reduce chronic pain, this program can help you meet your health goals for a happier, healthier life.',
    image: APCResult
  },
  {
    type: 'dh',
    name: 'Digestive Health',
    detailsTitle: 'Digestive Health Program',
    detailsDescription: 'Think of this program as the ultimate compliment to your work with a primary care provider. Whether you’re experiencing symptoms and don’t have a diagnosis yet, or you want to lose weight, have more energy, or reduce chronic pain, this program can help you meet your health goals for a happier, healthier life.',
    image: APCResult
  },
  {
    type: 'cmb',
    name: 'Cardiometabolic program',
    detailsTitle: 'Cardiometabolic Program',
    detailsDescription: 'We believe that if you weren’t born with it, it can be reversed. This program has helped people like you lose weight, reduce A1C, and reduce or eliminate medications',
    image: APCResult2
  },
]

export default function QuestionnaireResult() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const currentType = queryParams.get('type') || 'apc';

  const currentProgram = resultProgramInfo.find(program => program.type === currentType) || resultProgramInfo[1];

  const renderLeftSection = () => {
    return (
      <div className="flex items-center h-full w-full">
        <div className="w-full mx-auto text-center lg:max-w-2xl">
          <p className="text-[32px] font-bold text-deepPurple950 leading-snug max-w-xl mx-auto">
            You will be placed in {currentProgram?.name}
          </p>
          <p className="text-baseRelaxed mb-[29px] mt-4 text-green950">
            Check your email for login credentials to access the Ciba Health patient portal app. This is where you will meet your care team and receive further instructions.
          </p>
          <div className="w-full mx-auto">
            <a href={`${process.env.REACT_APP_ENV_URL}/login`}>
              <button
                className="bg-deepPurple900 hover:bg-deepPurple950 border-transparent disabled:bg-gray-100 px-6 lg:px-8 py-3 lg:py-[11px] text-nowrap text-white rounded-full text-[14px] lg:text-[16px] font-semibold focus:shadow-lightBlue100"
              >
                Log in to patient portal app
              </button>
            </a>
          </div>
        </div>
      </div>
    )
  }

  const renderRightSection = () => {
    return (
      <div className="flex items-center h-full w-full">
        <div className="w-full mx-auto text-left">
          <div className="mx-auto">
            <img
              src={currentProgram?.image}
              alt={currentProgram?.detailsTitle}
              className="w-full lg:w-[612px] self-stretch mb-[32px] rounded-[20px]"
            />
            <p className="text-lg lg:text-xl font-bold text-deepPurple950 mb-[9px]">
              {currentProgram?.detailsTitle}
            </p>
            <p className="text-baseRelaxed text-green950 italic max-w-full lg:max-w-[600px]">
              {currentProgram?.detailsDescription}
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <JibTheme>
      <div className='min-h-screen mx-auto flex flex-col justify-between items-center'>
        <EnrolmentHeader />
        <div className="flex flex-col lg:flex-row items-center justify-center flex-grow h-full mx-auto text-center pt-[56px] px-[30px] md:pt-12 gap-[56px] lg:gap-[99px]">
          {renderLeftSection()}
          {renderRightSection()}
        </div>
        <EnrolmentFooter />
      </div>
    </JibTheme>
  );
}
