import React from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/logo-dark.svg';
import magnacareLogo from 'assets/magnacare_logo.png';

const EnrolmentHeader = () => {
  const location = useLocation();

  const isJib = location.pathname?.startsWith('/company/jib') ?? false;


  return (
    <div className="flex w-full pt-14">
      <div className='flex flex-col md:flex-row gap-1 md:gap-[24px] mx-auto'>
        <Logo className="w-[145px] lg:w-[194px] mx-auto" />
        {
          isJib &&
          <img src={magnacareLogo} alt="Magnacare Logo" className="w-[150px] lg:w-[210px] mx-auto" />
        }
      </div>

    </div>
  );
};

export default EnrolmentHeader;
