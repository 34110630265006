import React from 'react';
import Questionnaire from '../Questionnaire';

const PATRIOT_RAIL_FORMID = process.env.REACT_APP_PATRIOT_RAIL_FORMID;

const PatriotRailPage = () => {
  return (
    <Questionnaire formId={PATRIOT_RAIL_FORMID} />
  )
}

export default PatriotRailPage